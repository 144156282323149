import {EnvironmentVariables, SearchRequest} from '../types/Types';
import {convertObjectToGetParameters, getEnv} from '@software/reactcommons';
import {
    LandingPageHostUrl,
    LandingPageServiceUrl,
    MasterDataServiceUrl, OperationsServiceUrl,
    QualitizeOnlineUrl
} from '../constants/Constants';

export const AuthenticationServiceBaseUrl = getEnv<EnvironmentVariables>().REACT_APP_AUTHENTICATION_SERVICE_URL || '';
export const VideoServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_VIDEO_SERVICE_URL || '';
export const TerminalServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_TERMINAL_SERVICE_URL || '';
export const ChartServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_CHART_SERVICE_BASE_URL || '';
export const QualitizeOnlineServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_QUALITIZE_ONLINE_SERVICE_URL || '';

export const DownloadReportUrl = (opaqueId: string) => `${getEnv<EnvironmentVariables>().REACT_APP_REPORT_DOWNLOAD_BASE_PATH || ''}${opaqueId}`;

export const CheckOnlineStateRoutes = {
    QualitizeOperations: `${OperationsServiceUrl}/api/v1/status`,
    QualitizeOnline: `${QualitizeOnlineUrl}/webApp/status`
}

/**
 * Define the routes which can be called.
 */
export const Route = {
    // User routes
    RefreshToken: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/refresh`,
    Logout: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/refresh/logout`,
    UpdateAccount: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/user/account`,
    LoadAccount: `${QualitizeOnlineUrl}/webApp/settings/account`,
    LoadSites: `${QualitizeOnlineUrl}/webApp/pages`,
    AuthenticateLegacy: `${QualitizeOnlineUrl}/webApp/authenticate`,
    ClearLegacyAccount: `${QualitizeOnlineUrl}/webApp/account/clear`,
    UpdateAccountLegacy: `${QualitizeOnlineUrl}/webApp/settings/account`,
    LoadUserInfo: `${VideoServiceUrl}/api/v1/user`,
    // Dashboard routes
    LoadDashboards: `${QualitizeOnlineUrl}/webApp/dashboards/load`,
    LoadDashboard: (dashboardId: number) => `${QualitizeOnlineUrl}/webApp/dashboards/load/${dashboardId}`,
    LoadElement: (id: number | string) => `${QualitizeOnlineUrl}/webApp/dashboards/element/${id}`,
    UpdateDashboardElement: `${QualitizeOnlineUrl}/webApp/dashboards/element/update`,
    DownloadChart: `${QualitizeOnlineUrl}/webApp/dashboards/element/download`,
    DownloadElement: (elementID: number) => `${QualitizeOnlineUrl}/webApp/dashboards/element/download/${elementID}`,
    // Video routes
    GetVideo: (videoID: string) => `${VideoServiceUrl}/api/v1/video/${videoID}`,
    LoadVideoCategories:`${VideoServiceUrl}/api/v1/aggregated/categories`,
    LoadVideoTags: `${VideoServiceUrl}/api/v1/aggregated/tagTypes`,
    LoadVideoMostViewed: (params?: SearchRequest) => `${VideoServiceUrl}/api/v1/search/mostViewed${params ? `?${convertObjectToGetParameters(params)}` : ''}`,
    LoadVideoLatest: (params?: SearchRequest) => `${VideoServiceUrl}/api/v1/search/latest${params ? `?${convertObjectToGetParameters(params)}` : ''}`,
    LoadVideoMostExciting: (params?: SearchRequest) => `${VideoServiceUrl}/api/v1/search/mostExciting${params ? `?${convertObjectToGetParameters(params)}` : ''}`,
    Search: `${VideoServiceUrl}/api/v1/search/v2`,
    UploadVideo: `${VideoServiceUrl}/api/v1/video/file`,
    SaveUploadedVideo: `${VideoServiceUrl}/api/v1/video/info`,
    UpdateVideo: `${VideoServiceUrl}/api/v1/video/info`,
    DeleteVideo: (videoID: string) => `${VideoServiceUrl}/api/v1/video/${videoID}`,
    EnableVideo: (videoID: string, enabled: boolean) => `${VideoServiceUrl}/api/v1/video/${videoID}/enabled/${enabled.toString()}`,
    SetReaction: (videoID: string) => `${VideoServiceUrl}/api/v1/video/${videoID}/reaction`,
    GetComments: (videoID: string) => `${VideoServiceUrl}/api/v1/comments/video/${videoID}`,
    PostComment: (videoID: string) => `${VideoServiceUrl}/api/v1/video/${videoID}/comment`,
    DeleteComment: (videoID: string, commentID: number) => `${VideoServiceUrl}/api/v1/video/${videoID}/comment/${commentID}`,
    LoadHighlights: `${VideoServiceUrl}/api/v1/highlights`,
    HighlightVideo: (id: string) => `${VideoServiceUrl}/api/v1/highlight/video/${id}`,
    LoadStatistic: `${VideoServiceUrl}/api/v1/admin`,
    Wishes: `${VideoServiceUrl}/api/v1/wishes`,
    UpvoteWish: (id: number) => `${VideoServiceUrl}/api/v1/wishes/${id}/upvote`,
    RecordView: (videoId: string) => `${VideoServiceUrl}/api/v1/video/${videoId}/viewed`,
    UploadPlaylistImage: `${VideoServiceUrl}/api/v1/playlist/file`,
    UpdateVideoPlaylist: `${VideoServiceUrl}/api/v1/playlist`,
    GetVideoPlaylists: (params?: SearchRequest) => `${VideoServiceUrl}/api/v1/playlists/search${params ? `?${convertObjectToGetParameters(params)}` : ''}`,
    HighlightVideoPlaylist: (playlistId: string) => `${VideoServiceUrl}/api/v1/playlist/${playlistId}/highlight`,
    EnableVideoPlaylist: (playlistId: string, enabled: boolean) => `${VideoServiceUrl}/api/v1/playlist/${playlistId}/enabled/${enabled}`,
    DeleteVideoPlaylist: (playlistId: string) => `${VideoServiceUrl}/api/v1/playlist/${playlistId}`,
    GetVideoPlaylistHighlights: `${VideoServiceUrl}/api/v1/playlist/highlights`,
    RecordPlaylistView: `${VideoServiceUrl}/api/v1/playlist/view`,
    // Error Route
    PostError: `${QualitizeOnlineUrl}/webApp/logError`,

    // Authentication
    Authenticate: {
        ViaPassword: `${QualitizeOnlineServiceUrl}/api/v1/login/password`,
        RequestPasswordReset: `${QualitizeOnlineServiceUrl}/api/v1/login/password/reset/request`,
        ResetPassword: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/password/reset/update`,
        ValidateResetPasswordLink: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/password/reset/validate`,
        InvalidateResetPasswordToken: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/password/reset/invalidate`,
        RequestLoginToken: `${QualitizeOnlineServiceUrl}/api/v1/login/loginTokens/request`,
        ValidateLoginToken: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/loginTokens/validate`,
        AuthenticateViaLoginToken: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/loginTokens`,
        InvalidateLoginToken: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/loginTokens/invalidate`,
        LoadLoginMethods: `${QualitizeOnlineServiceUrl}/api/v1/login/methods`,
        LoadPersonAccount: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/user/account`,
        Registration: {
            SubmitPassword: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/password/registration/update`,
            Validate: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/password/registration/validate`
        },
        GetEmailViaLoginToken: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/loginTokens/email`,
        OIDC: {
            Validate: `${QualitizeOnlineServiceUrl}/api/v1/login/oidc/authorizationCode`,
            InvalidateAuthorizationCode: `${AuthenticationServiceBaseUrl}/api/v1/authenticate/user/oidc/authorizationCode/invalidate`
        }
    },
    DeepLink: {
        Load: `${QualitizeOnlineServiceUrl}/api/v1/deepLinks/token`
    },

    // Operations
    Operations: {
        LoadManualToDos: `${OperationsServiceUrl}/api/v1/manualQuestionnaires`,
        LoadAvailableToDos: `${OperationsServiceUrl}/api/v1/availableQuestionnaires`,
        LoadOperationsToDos: `${OperationsServiceUrl}/api/v1/overview/load`,
        LoadOperationsRequests: `${OperationsServiceUrl}/api/v1/overview/requests/load`,
        LoadOperationsFinishedToDos: `${OperationsServiceUrl}/api/v1/overview/finished/load`,
        LoadOperationsApprovedRequests: `${OperationsServiceUrl}/api/v1/overview/requests/approved/load`,
        LoadOperationsDeclinedRequests: `${OperationsServiceUrl}/api/v1/overview/requests/declined/load`,
        LoadOperationsExpiredRequests: `${OperationsServiceUrl}/api/v1/overview/requests/expiredApproval/load`,
        LoadOperationsReviewToDos: `${OperationsServiceUrl}/api/v1/overview/review/load`,
        LoadOperationsReviewRequestsToDos: `${OperationsServiceUrl}/api/v1/overview/review/requests/load`,
        LoadOperationsUserReviewToDos: `${OperationsServiceUrl}/api/v1/overview/review/user/load`,
        LoadOperationsUserReviewRequests: `${OperationsServiceUrl}/api/v1/overview/review/requests/user/load`,
        LoadSubordinatedToDos: `${OperationsServiceUrl}/api/v1/toDo/subordinated/active`,
        LoadSubordinatedUserReviewToDos: `${OperationsServiceUrl}/api/v1/toDo/subordinated/reviews`,
        LoadSubordinatedUserReviewRequests: `${OperationsServiceUrl}/api/v1/toDo/subordinated/requests/reviews`,
        LoadOperationsEditors: (search: string = '') => `${OperationsServiceUrl}/api/v1/email/recipients?search=${search}`,
        SaveOperationsEditors: `${OperationsServiceUrl}/api/v1/survey/addEditors`,
        DownloadOperationsToDos: `${OperationsServiceUrl}/api/v1/toDo/download`,
        SendOperationsToDoEmail: `${OperationsServiceUrl}/api/v1/toDo/email/send`,
        CreateToDo: `${OperationsServiceUrl}/api/v1/toDo/create`,
        ChangeDeadline: `${OperationsServiceUrl}/api/v1/toDo/deadline`,
        ReOpenToDo: `${OperationsServiceUrl}/api/v1/toDo/reOpen`,
        ChangeReviewDeadline: `${OperationsServiceUrl}/api/v1/toDo/review/deadline`,
        AddReviewer: `${OperationsServiceUrl}/api/v1/toDo/review/reviewer`,
        LoadToDo: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/overview/${id}`,
        LoadSubordinatedToDo: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/subordinated/overview/${id}`,
        LoadArchiveToDo: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/archive/overview/${id}`,
        LoadToDoReview: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/review/active/${id}`,
        LoadFinishedToDoReview: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/review/finished/${id}`,
        SaveToDoReviewRemark: `${OperationsServiceUrl}/api/v1/toDo/review/remark`,
        FinishToDoReview: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/review/finish/${id}`,
        DeclineToDoReview: `${OperationsServiceUrl}/api/v1/toDo/review/decline`,
        ReOpenToDoReview: (id: number | string) => `${OperationsServiceUrl}/api/v1/toDo/review/reOpen/${id}`,
        LoadOpenToDosForToDoDefinitionAndLocation: `${OperationsServiceUrl}/api/v1/load/open`,
        LoadPreviousToDosForToDo: (toDoId: number) => `${OperationsServiceUrl}/api/v1/toDo/previous/${toDoId}`,
        SendContinueEmail: `${OperationsServiceUrl}/api/v1/survey/continue/email`,
        LoadToDoGroups: `${OperationsServiceUrl}/api/v1/overview/groups`,
        UploadFile: (toDoId: number, questionId: number, keepOriginal: boolean, existingFilePath: string) => `${OperationsServiceUrl}/api/v1/survey/file/${questionId}/${toDoId}/${keepOriginal}${existingFilePath.trim().length ? `/${existingFilePath}` : ''}`,
        SaveOperationsToDo: `${OperationsServiceUrl}/api/v1/survey/answer`,
        DeleteOperationsToDoAnswer: `${OperationsServiceUrl}/api/v1/survey/answer`,
        DeleteFile: (toDoId: number, questionId: number, path: string) => `${OperationsServiceUrl}/api/v1/survey/file/delete/${questionId}/${toDoId}/${path}`,
        FinishToDo: (toDoId: number) => `${OperationsServiceUrl}/api/v1/survey/finish/${toDoId}`,
        PreFinishToDo: (toDoId: number) => `${OperationsServiceUrl}/api/v1/survey/preFinish/${toDoId}`,
        AutoApproveToDo: (toDoId: number) => `${OperationsServiceUrl}/api/v1/survey/autoApprove/${toDoId}`,
        AutoDeclineToDo: (toDoId: number) => `${OperationsServiceUrl}/api/v1/survey/autoDecline/${toDoId}`,
        GetToDoLastUpdateTimestamp: (toDoId: number) => `${OperationsServiceUrl}/api/v1/lastUpdate/${toDoId}`,
        HeadDetection: `${OperationsServiceUrl}/api/v1/survey/image/headDetection`
    },
    // User
    User: {
        LoadCustomerList: `${QualitizeOnlineUrl}/webApp/customer/list`,
        ChangeCustomer: `${QualitizeOnlineUrl}/webApp/customer/change`,
        LoadUserAccess: `${QualitizeOnlineUrl}/webApp/user/access`,
        LoadSelectedCustomer: `${QualitizeOnlineUrl}/webApp/user/selectedCustomer`,
        GetResolvedHierarchyNodeHierarchy: `${MasterDataServiceUrl}/api/v1/selfservice/hierarchyNodes/jwtUser/resolvedHierarchy`,
        GetHierarchyNodeFeedbackCollectionPoints: (hierarchyNodeId: number) => `${QualitizeOnlineUrl}/webApp/feedback/web/collectionPoints/hierarchyNode/${hierarchyNodeId}`
    },
    Reports: {
        Load: `${QualitizeOnlineUrl}/webApp/reports`,
        LoadReport: (id: string) => `${QualitizeOnlineUrl}/webApp/reports/${id}`,
        LoadScopes: `${QualitizeOnlineUrl}/webApp/reports/availableScopes`,
        LoadTimeSpans: `${QualitizeOnlineUrl}/webApp/reports/availableTimeSpans`,
        CheckDownloadAccess: (opaqueId: string) => `${QualitizeOnlineUrl}/static/reports/check/${opaqueId}`,
    },
    LegacyDashboard: {
        ChangeLocation: `${QualitizeOnlineUrl}/webApp/feedback/dashboard/changeLocation`,
        LoadElements: `${QualitizeOnlineUrl}/webApp/feedback/dashboard/elements`,
        LoadDashboardPreview: `${QualitizeOnlineUrl}/webApp/feedback/dashboard/preview`,
    },
    LocationComparison: {
        LoadLocationComparisonPreview: `${QualitizeOnlineUrl}/webApp/feedback/locationComparison/preview`,
        LoadLocationComparison: `${QualitizeOnlineUrl}/webApp/feedback/locationComparison`,
        LoadMapTopologyJson: (iso3166Codes: string[]) => `${ChartServiceUrl}/api/v1/map/topology.json?iso3166Codes=${iso3166Codes.join(',')}`,
        LoadColorThresholds: `${QualitizeOnlineUrl}/webApp/feedback/colorThresholds`,
        LoadFilterQuestion: `${QualitizeOnlineUrl}/webApp/feedback/locationComparison/filterQuestions`
    },
    Administration: {
        LoadOpeningHours: (locationId: number | string = '') => `${QualitizeOnlineUrl}/webApp/administration/openingHours/${locationId}`,
        SaveOpeningHours: `${QualitizeOnlineUrl}/webApp/administration/openingHours`,
        LoadTerminals: `${TerminalServiceUrl}/devices/status`,
        LoadQrCodes: `${TerminalServiceUrl}/qrCodes/status`,
        LoadIncidentProblems: `${TerminalServiceUrl}/cases/problemList`,
        UploadIncidentFile: (terminalId: number) => `${TerminalServiceUrl}/cases/attachments/${terminalId}`,
        CreateIncident: `${TerminalServiceUrl}/cases/externalNote`,
        DeleteUploadedIncidentFile: (terminalId: number, fileName: string) => `${TerminalServiceUrl}/cases/attachments/${terminalId}/${fileName}`,
        LoadDepartments: `${QualitizeOnlineUrl}/webApp/departments`,
        CreateNewDepartment: `${QualitizeOnlineUrl}/webApp/departments`,
        EditDepartment: `${QualitizeOnlineUrl}/webApp/departments`,
        CreateNewDepartmentGroup: `${QualitizeOnlineUrl}/webApp/departmentGroups`,
        EditDepartmentGroup: `${QualitizeOnlineUrl}/webApp/departmentGroups`,
        UploadDepartmentImage: `${QualitizeOnlineUrl}/webApp/departments/image`,
        LoadStickies: `${QualitizeOnlineUrl}/webApp/stickies`,
        CreateNewSticky: `${QualitizeOnlineUrl}/webApp/sticky`,
        EditSticky: `${QualitizeOnlineUrl}/webApp/sticky`,
        DeleteSticky: (stickyId: number) => `${QualitizeOnlineUrl}/webApp/sticky/${stickyId}`,
        LoadAccounts: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/accounts/search`,
        LoadAccountProfiles: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/profiles`,
        LoadAccountAuditLogs: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/audit/loginActivity`,
        DeactivateAccounts: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/accounts/deactivate`,
        DisableAccounts: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/accounts/temporarilyDisabledState/disableManually`,
        EnableAccounts: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/accounts/temporarilyDisabledState/reEnable`,
        ExportAccounts: `${AuthenticationServiceBaseUrl}/api/v1/selfservice/admin/accounts/export`,
    },
    Terminals: {
        Incidents: {
            ImageUrl: (terminalId: number, src: string) => `${TerminalServiceUrl}/cases/attachment/${terminalId}/${src}`
        }
    },
    Dashboards: {
        Export: {
            ExportElement: (elementId: number, exportId: number) => `${QualitizeOnlineUrl}/webApp/dashboards/element/export/${elementId}/${exportId}`,
            DownloadExportElement: (opaqueId: string) => `${QualitizeOnlineUrl}/webApp/dashboards/element/export/download/${opaqueId}`
        }
    },
    LandingPage: {
        PostLoad: `${LandingPageServiceUrl}/api/v1/selfService/landingPages/search`,
        GetLoadSingleLandingPage: (id: string) => `${LandingPageServiceUrl}/api/v1/selfService/landingPages/${id}`,
        PostCreate: `${LandingPageServiceUrl}/api/v1/selfService/landingPages`,
        PutUpdate: (opaqueId: string) => `${LandingPageServiceUrl}/api/v1/selfService/landingPages/${opaqueId}`,
        PostDownloadQRCodes: `${LandingPageServiceUrl}/api/v1/selfService/landingPages/generateMQRs`,
        PostFileUpload: `${LandingPageServiceUrl}/api/v1/selfService/cdnFiles`,
        PostCdnFiles: `${LandingPageServiceUrl}/api/v1/selfService/cdnFiles/search`,
        GetCdnFile: (id: number) => `${LandingPageServiceUrl}/api/v1/selfService/cdnFiles/${id}`,
        PostLandingPagePreview: (opaqueId: string) => `${LandingPageServiceUrl}/api/v1/selfService/landingPages/${opaqueId}/toDisplayConfig`,
        PostCloneLandingPage: (opaqueId: string) => `${LandingPageServiceUrl}/api/v1/selfService/landingPages/${opaqueId}/clone`,
        GetFontCss: (opaqueId: string) => `${LandingPageServiceUrl}/${opaqueId}/fonts.css`,
        OpenLandingPage: (opaqueId: string) => `${LandingPageHostUrl}/${opaqueId}?__ft=true`
    },
    Locales: {
        GetLocales: `${MasterDataServiceUrl}/api/v1/locales?languageOnly=true`,
        GetLocaleName: (locale: string) => `${MasterDataServiceUrl}/api/v1/locales/${locale}`
    }
};