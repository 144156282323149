import {Converter} from 'showdown';
import {getEnv} from '@software/reactcommons';
import {EnvironmentVariables} from '../types/Types';
import {DateTime, DateTimeFormatOptions} from 'luxon';

export const Development: boolean = process.env.NODE_ENV !== 'production';

export const Server: string = getEnv<EnvironmentVariables>().REACT_APP_BACKEND_BASE_ROUTE || '';

export const Protocol: string = `${getEnv<EnvironmentVariables>().REACT_APP_PROTOCOL || 'https'}://`;

export const BaseUrl: string = Development ? '' : '';

export const VideoServiceWebSocketUrl = getEnv<EnvironmentVariables>().REACT_APP_VIDEO_SERVICE_WEB_SOCKET_URL || '';
export const QualitizeOnlineWebSocketUrl = getEnv<EnvironmentVariables>().REACT_APP_QUALITIZE_ONLINE_WEB_SOCKET_URL || '';
export const OperationsServiceWebSocketUrl = getEnv<EnvironmentVariables>().REACT_APP_OPERATIONS_SERVICE_WEB_SOCKET_URL || '';

export const VideoHighlightSliderDurationInS = Number(getEnv<EnvironmentVariables>().REACT_APP_VIDEO_HIGHLIGHT_SLIDER_DURATION_IN_S || '10');
export const QualitizeOnlineUrl = getEnv<EnvironmentVariables>().REACT_APP_QUALITIZE_ONLINE_URL || '';
export const OperationsServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_OPERATIONS_SERVICE_URL || '';

export const LandingPageServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_LANDING_PAGE_SERVICE_URL || '';
export const LandingPageCdnUrl = getEnv<EnvironmentVariables>().REACT_APP_LANDING_PAGE_CDN_URL || '';
export const LandingPageHostUrl = getEnv<EnvironmentVariables>().REACT_APP_LANDING_PAGE_HOST_URL || '';

export const MasterDataServiceUrl = getEnv<EnvironmentVariables>().REACT_APP_MASTER_DATA_SERVICE_URL || '';

export const GridItemHeight = 60;

export const IconImagePath = `${getEnv<EnvironmentVariables>().PUBLIC_URL || ''}/icons/`;

export const OperationsImagePath = `${BaseUrl}${getEnv<EnvironmentVariables>().REACT_APP_OPERATIONS_IMAGE_URL || ''}`;
export const OperationsTaskAttachmentPath = `${BaseUrl}${getEnv<EnvironmentVariables>().REACT_APP_OPERATIONS_TASK_ATTACHMENT_URL || ''}`;

export const MaxNumberOfVideosInVideoDashboard = Number(getEnv<EnvironmentVariables>().REACT_APP_MAX_NUMBER_OF_VIDEOS_IN_VIDEO_DASHBOARD || '10');

export const OperationsSyncTimeout = Number(getEnv<EnvironmentVariables>().REACT_APP_OPERATIONS_SYNC_TIMEOUT_IN_MS || '5000');

export const LinkRegex = '(?:(?:(?:https?|ftp):)?\\/\\/)?(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z0-9\\u00a1-\\uffff][a-z0-9\\u00a1-\\uffff_-]{0,62})?[a-z0-9\\u00a1-\\uffff]\\.)+(?:[a-z\\u00a1-\\uffff]{2,}\\.?))';

export const RedirectPathParameterName = getEnv<EnvironmentVariables>().REACT_APP_REDIRECT_PATH_PARAMETER_NAME || 'redirectPath';
export const RedirectPathHeaderName = 'X-Redirect-Path';
export const DeepLinkTokenParameterName = getEnv<EnvironmentVariables>().REACT_APP_DEEP_LINK_TOKEN_PARAMETER_NAME || 'dlt';
export const DeepLinkTokenHeaderName = 'X-Deep-Link-Token';

export const LoginTokenParameterName = getEnv<EnvironmentVariables>().REACT_APP_LOGIN_TOKEN_PARAMETER_NAME || 'lt';
export const PasswordResetTokenParameterName = getEnv<EnvironmentVariables>().REACT_APP_PASSWORD_RESET_TOKEN_PARAMETER_NAME || 'prt';
export const InvalidateTokenParameterName = getEnv<EnvironmentVariables>().REACT_APP_INVALIDATE_TOKEN_PARAMETER_NAME || 'invalidate';

export const MaximumNumberOfTextSearchHistoryEntries = Number(getEnv<EnvironmentVariables>().REACT_APP_MAXIMUM_NUMBER_OF_TEXT_SEARCH_HISTORY_ENTRIES || '100');

export const LanguageCookieName = getEnv<EnvironmentVariables>().REACT_APP_LANGUAGE_COOKIE_NAME || 'PLAY_LANG';
export const markdownConverter = new Converter({
    openLinksInNewWindow: true,
    emoji: true
});

export const formatTime = (date: Date) => DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_24_SIMPLE);

export const DateTimeFormat: DateTimeFormatOptions = {
    ...DateTime.DATETIME_SHORT,
    month: '2-digit',
    day: '2-digit'
};

export const DateFormat: DateTimeFormatOptions = {
    ...DateTime.DATE_SHORT,
    month: '2-digit',
    day: '2-digit'
};

export const DateTimeWithWeekDayFormat: DateTimeFormatOptions = {
    ...DateTime.DATETIME_SHORT,
    month: '2-digit',
    day: '2-digit'
};


export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const CardColors = [{
    color: '#221E50',
    textColor: 'white'
}, {
    color: '#E8706D',
    textColor: 'white'
}, {
    color: '#CDD3DB',
    textColor: 'white'
}, {
    color: '#5A7E97',
    textColor: 'white'
}, {
    color: '#183A5B',
    textColor: 'white'
}, {
    color: '#5CC7C0',
    textColor: 'white'
}, {
    color: '#DB4A4A',
    textColor: 'white'
}, {
    color: '#8A375B',
    textColor: 'white'
}, {
    color: '#FAE0BA',
    textColor: 'white'
}];

export const getCardColor = (index: number) => CardColors[index % CardColors.length];