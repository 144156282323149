import {denormalize, FetchStatus, normalize, notUndefined} from '@software/reactcommons';
import {
    AggregatingFeedbackElementConfiguration, ChartConfigType,
    ComparisonPeriod,
    ComparisonPeriodHandling,
    Dashboard,
    DashboardElement, DashboardFilterResponse,
    DashboardFilterScope,
    DashboardGroupResponse,
    DashboardResponseType,
    DashboardState,
    DataSourceComparisonElementConfiguration,
    ElementRestriction,
    FileDashboardElementConfiguration,
    LineChartElementConfiguration,
    LoadFilterDashboardResponse,
    Period,
    PeriodBlackList,
    ResponseDashboard,
    SingleDashboardResponse,
    TableElementConfiguration,
    TextElementConfiguration
} from './types';
import {
    DashboardElementPayload,
    downloadChartAction,
    DownloadChartPayload,
    downloadElementAction,
    DownloadElementPayload,
    EnableOnlyIncludeFinishedFeedbackActionPayload,
    EnableShowIconsActionPayload,
    EnableShowThresholdActionPayload,
    exportElementActionAndSaga,
    ExportElementPayload, loadDashboardActionAndSaga,
    loadDashboardsActionAndSaga,
    SearchTableActionPayload,
    SetAllElementsCustomComparisonPeriodPayload,
    SetCustomComparisonPeriodPayload,
    SetDashboardElementCategoryFilterPayload,
    SetDashboardElementGroupingPayload,
    SetDashboardElementPeriodGroupingFilterPayload,
    SetDashboardElementQuestionPayload,
    SetDashboardElementRankingOrderPayload,
    SetDashboardElementRankingTypePayload,
    SetDashboardElementRatingFilterPayload,
    SetDashboardElementSearchFilterPayload,
    SetDashboardElementVisiblePayload,
    SetDashboardFileElementDisplayTypePayload,
    SetGlobalRefreshTimestampActionPayload,
    SetSelectedComparisonPeriodPayload,
    UpdateAllElementsCustomPeriodActionPayload,
    UpdateElementCustomPeriodActionPayload,
    UpdateElementDataSuccessActionPayload,
    UpdateElementFetchStatusActionPayload,
    UpdateElementFilterActionPayload,
    UpdateElementPagePayload,
    UpdateElementPeriodActionPayload,
    UpdateElementRestrictionsActionPayload,
    UpdateElementSubChartTypeActionPayload,
    UpdateFilterActionPayload,
    UpdateGlobalFilterActionPayload
} from './actions';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {BarChartType, DashboardChartConfiguration} from '../../../types/charts/chartTypes';
import {cloneDeep, isArray, isEqual, mergeWith} from 'lodash';
import {setSelectedCustomerIdAction} from '../user/actions';
import {MaximumNumberOfTextSearchHistoryEntries} from '../../../constants/Constants';
import {fetchAuthenticateSuccess} from '@software/reactcommons-security';

const initialState: DashboardState = {
    fetchStatus: FetchStatus.Default,
    dashboardFetchStatus: {},
    questions: {},
    groups: {},
    feedbackCollectionPoints: {},
    dashboards: {},
    selectedDashboard: -1,
    live: {
        enabled: false,
        interval: 60000
    },
    inline360Enabled: true
};

const getDefaultStackedBarChartConfig = (numberOfBars: number = 1) => ({
    viewBox: {
        width: numberOfBars * 500,
        height: 1000
    },
    dimension: {
        width: numberOfBars * 500,
        height: 1000
    },
    maxPointFontSize: 1.5
});

const getDefaultWordCloudConfig = () => ({
    viewBox: {
        width: 1000,
        height: 1000
    },
    dimension: {
        width: 1000,
        height: 1000
    }
});

const getDefaultHorizontalBarChartConfig = () => ({
    viewBox: {
        width: 1000,
        height: 1000
    },
    dimension: {
        width: 1000,
        height: 1000,
        padding: {
            top: 20
        }
    },
    maxPointFontSize: 1.5
});

const getDefaultLineChartConfig = () => ({
    viewBox: {
        width: 2000,
        height: 500
    },
    dimension: {
        width: 2000,
        height: 500
    },
    maxPointFontSize: 1.5
});

const getDefaultGaugeConfig = () => ({
    viewBox: {
        width: 1000,
        height: 1000
    },
    dimension: {
        width: 1000,
        height: 1000
    },
    cx: 500,
    cy: 500
});

const getDefaultPodiumConfig = () => ({
    maxPointFontSize: 1.5
});

const setElementDownloadFetchStatus = (fetchStatus: FetchStatus) => (state: DashboardState, action: PayloadAction<DownloadChartPayload | DownloadElementPayload>) => {
    const dashboard = state.dashboards[state.selectedDashboard];
    const element = dashboard?.elements[action.payload.id];
    if (dashboard && element) {
        element.downloadFetchStatus = fetchStatus;
    }
}

const setElementExportFetchStatus = (fetchStatus: FetchStatus) => (state: DashboardState, action: PayloadAction<ExportElementPayload>) => {
    const dashboard = state.dashboards[state.selectedDashboard];
    const element = dashboard?.elements[action.payload.dashboardElementId];
    if (dashboard && element) {
        if (!element.exportFetchStatus) {
            element.exportFetchStatus = {};
        }
        element.exportFetchStatus[action.payload.exportId] = fetchStatus;
    }
}

const getNextCommonFilterValue = <T>(current: Record<string, T>,
                                     filter: Record<string, T>): Record<string, T> => {
    let next = {...current};
    Object.keys({...filter}).forEach(key => {
        const destructured = cloneDeep(next[key]);
        const destructuredFilter = cloneDeep(filter[key]);
        if (!isEqual(destructured, destructuredFilter)) {
            delete next[key];
        }
    });
    return next;
}

const setGlobalFilter = (dashboard: Dashboard) => {
    let handledDashboard = {...dashboard};
    const elements = denormalize(handledDashboard.elements);
    // Get the common restrictions for all elements of the dashboard
    const commonRestrictions = elements.reduce((current, element) => {
            let next = {...current};
            Object.keys(element.config.restrictions).forEach(key => {
                const destructured = next[key];
                const destructuredFilter = element.config.restrictions[key];
                if (Boolean(destructured?.selected?.length) && !isEqual(destructured?.selected?.map(it => it.toString()), destructuredFilter?.selected?.map(it => it.toString()))) {
                    delete next[key];
                }
            });
            return next;
        },
        elements[0]?.config.restrictions || {} as Record<string, ElementRestriction>);
    // Get the common filter for all elements of the dashboard
    const commonFilter = elements.reduce((current, element) =>
            getNextCommonFilterValue<string[]>(current, element.config.filter),
        elements[0]?.config.filter || {} as Record<string, string[]>)
    handledDashboard.selectedFilter = {...handledDashboard.selectedFilter};
    handledDashboard.selectedRestrictions = {...handledDashboard.selectedRestrictions};
    // Restore global filter
    const commonFilterKeys = Object.keys(commonFilter);
    const commonRestrictionKeys = Object.keys(commonRestrictions);
    if (!commonFilterKeys.length) {
        handledDashboard.selectedFilter = {};
    } else {
        commonFilterKeys.forEach(key => handledDashboard.selectedFilter[key] = commonFilter[key]);
    }
    if (!commonRestrictionKeys.length) {
        handledDashboard.selectedRestrictions = {};
    } else {
        // Restore global restrictions
        Object.keys(commonRestrictions).forEach(key =>
            handledDashboard.selectedRestrictions[key] =
                commonRestrictions[key]?.selected?.map(it => denormalize(dashboard.availableFilter[key]?.availableFilter || {})
                    .filter(filter => filter.id === Number(it))[0])
                    .filter(notUndefined));
    }

    return handledDashboard;
}

const fixSelectedRestrictions = (availableFilter: DashboardFilterResponse[], selected?: (string | number)[]) => {
    return selected?.filter(selected => Boolean(availableFilter.some(filter => Boolean(filter.availableFilter?.some(availableFilter => availableFilter.id === Number(selected))))));
}

const mapDashboard = (dashboard: ResponseDashboard) => {
    const mappedDashboard: Dashboard = {
        availableFilter: {},
        elements: {},
        orderNumber: dashboard.orderNumber,
        id: dashboard.id,
        name: dashboard.name,
        refreshTimestamp: dashboard.refreshTimestamp,
        selectedFilter: dashboard.selectedFilter || {},
        selectedRestrictions: {},
        refreshInterval: dashboard.configuration?.refreshInterval,
        customPeriodFilter: dashboard.configuration?.customPeriodFilter || []
    };
    dashboard.selectedFilter = {};
    mappedDashboard.elements = normalize(dashboard.elements);

    // Check if there are preselected dashboard filter
    const preselectedFilter = (dashboard.configuration?.availableFilter || []).filter(it => Boolean(it.selected?.length));

    dashboard.elements.forEach(element => {
        const mappedElement = {
            ...mappedDashboard.elements[element.id],
            isDragged: false,
            showConfig: false,
            selectedPeriod: Period.LastWeek,
            fetchStatus: FetchStatus.Active,
            visible: false,
            dashboardId: dashboard.id,
        } as DashboardElement;
        if (mappedElement.config.restrictions) {
            // Remove all selected restrictions which are not part of the available restrictions
            // Due to conversion in backend, some restriction ids are no longer used
            denormalize(mappedElement.config.restrictions).filter(it => Boolean(it.selected?.length)).forEach(it => {
                mappedElement.config.restrictions[it.name] = {
                    ...it,
                    selected: fixSelectedRestrictions(dashboard.configuration?.availableFilter ?? [], it.selected)
                }
                // If no restriction is selected after filtering, delete the complete restriction from the element
                if (!mappedElement.config.restrictions[it.name]?.selected?.length) {
                    delete mappedElement.config.restrictions[it.name];
                }
            });

            // Inject the default restrictions, but keep the selected custom restrictions of the element.
            preselectedFilter.forEach(it => {
                // Inject preselected filter if the restriction is not already set by user
                // and either the scope of the filter is global dashboard or the element listens on the filter
                if (!element.config.restrictions[it.name] && (it.scope === DashboardFilterScope.Dashboard || element.config.availableElementFilter.includes(it.name))) {
                    element.config.restrictions[it.name] = it;
                }
            })

        }
        // Check if config enforces setting of comparison period
        if ((element.config as DataSourceComparisonElementConfiguration).comparisonPeriodHandling === ComparisonPeriodHandling.RequireComparisonPeriod && !element.config.comparison) {
            element.config.comparison = {};
        }
        mappedDashboard.elements[element.id] = mappedElement;
    });

    mappedDashboard.availableFilter = {};
    if (dashboard.configuration?.availableFilter) {
        dashboard.configuration.availableFilter.filter(it => Boolean(it.availableFilter)).forEach((filter) => {
            mappedDashboard.availableFilter[filter.name] = {
                ...filter,
                availableFilter: normalize(filter.availableFilter)
            }
        });
    }
    mappedDashboard.filter = dashboard.parameterFilter || [];
    return setGlobalFilter(mappedDashboard);
}

const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        fetchLoadDashboardFilterSuccess: (state, action: PayloadAction<LoadFilterDashboardResponse>) => {
            state.questions = normalize(action.payload.questions);
            state.feedbackCollectionPoints = normalize(action.payload.feedbackCollectionPoints);
            Object.keys(action.payload.filter).forEach(id => {
                let dashboard = state.dashboards[id];
                if (dashboard) {
                    dashboard.filter = action.payload.filter[id] || [];
                }
            });
        },
        toggleElementConfig: (state, action: PayloadAction<number>) => {
            state.dashboards[state.selectedDashboard]!.elements[action.payload]!.showConfig = !state.dashboards[state.selectedDashboard]!.elements[action.payload]!.showConfig;
        },
        setDashboardElementVisible: (state, action: PayloadAction<SetDashboardElementVisiblePayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.id];
            if (element) {
                element.visible = action.payload.visible;
            }
        },
        updateElementPeriod: (state, action: PayloadAction<UpdateElementPeriodActionPayload>) => {
            const element = state.dashboards[action.payload.dashboardId || state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                element.config.selectedPeriod = action.payload.period;
                if (action.payload.period !== Period.Custom) {
                    element.config.periods = [];
                }
                if (element.config.type === ChartConfigType.LineChart) {
                    (element.config as LineChartElementConfiguration).selectedPeriodGrouping = undefined;
                }
            }
        },
        updateAllElementsPeriod: (state, action: PayloadAction<Period>) => {
            const dashboard = state.dashboards[state.selectedDashboard];
            denormalize(dashboard?.elements || {}).forEach((element: DashboardElement) => {
                // Check if selected period is blacklisted for the chart type, if so, do not change the period
                const isPeriodBlacklisted = PeriodBlackList[element.config.type || -1]?.includes(action.payload) || false;
                if (!isPeriodBlacklisted) {
                    // Cast to dashboard because it was already checked that it exists
                    element.config.selectedPeriod = action.payload;
                    element.config.periods = action.payload !== Period.Custom ? [] : element.config.periods;
                    if (element.config.type === ChartConfigType.LineChart) {
                        (element.config as LineChartElementConfiguration).selectedPeriodGrouping = undefined;
                    }
                }
            });
        },
        updateElementCustomPeriod: (state, action: PayloadAction<UpdateElementCustomPeriodActionPayload>) => {
            const element = state.dashboards[action.payload.dashboardId || state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                element.config.selectedPeriod = action.payload.type;
                element.config.periods = [action.payload.period];
            }
        },
        updateAllElementsCustomPeriod: (state, action: PayloadAction<UpdateAllElementsCustomPeriodActionPayload>) => {
            denormalize(state.dashboards[state.selectedDashboard]?.elements || {}).forEach((element) => {
                const isPeriodBlacklisted = PeriodBlackList[element.config.type || -1]?.includes(action.payload.type) || false;
                if (!isPeriodBlacklisted) {
                    element.config.selectedPeriod = action.payload.type;
                    element.config.periods = [action.payload.period];
                }
            });
        },
        updateElementFilter: (state, action: PayloadAction<UpdateElementFilterActionPayload>) => {
            const element = state.dashboards[action.payload.dashboardId || state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                element.config.filter[action.payload.name] = action.payload.options;
            }
        },
        updateElementDataActive: (state, action: PayloadAction<UpdateElementFetchStatusActionPayload>) => {
            const element = state.dashboards[action.payload.dashboard]?.elements[action.payload.id];
            if (element) {
                element.fetchStatus = FetchStatus.Active;
            }
        },
        updateElementDataSuccess: (state, action: PayloadAction<UpdateElementDataSuccessActionPayload>) => {
            const element = state.dashboards[action.payload.dashboard]?.elements[action.payload.id];
            if (element) {
                let defaultConfig;
                const {
                    width,
                    height
                } = element.config || {};
                if (width && height) {
                    defaultConfig = {
                        viewBox: {
                            width,
                            height
                        },
                        dimension: {
                            width,
                            height
                        },
                        maxPointFontSize: 1.5
                    }
                } else {
                    const chartType = element.config.type;
                    if (chartType !== undefined) {
                        switch (chartType) {
                            case ChartConfigType.BarChart:
                                if (element.config.subChartType === BarChartType.Stacked) {
                                    defaultConfig = getDefaultStackedBarChartConfig();
                                } else {
                                    defaultConfig = getDefaultHorizontalBarChartConfig();
                                }
                                break;
                            case ChartConfigType.LineChart:
                                defaultConfig = getDefaultLineChartConfig();
                                break;
                            case ChartConfigType.Gauge:
                                defaultConfig = getDefaultGaugeConfig();
                                break;
                            case ChartConfigType.Podium:
                                defaultConfig = getDefaultPodiumConfig();
                                break;
                            case ChartConfigType.WordCloud:
                                defaultConfig = getDefaultWordCloudConfig();
                                break;
                            default:
                                break;
                        }
                    }
                }
                if (action.payload.merge) {
                    let merged = cloneDeep(element.chartConfig);
                    // Merge with custom merger which concatenates arrays instead of replacing them
                    element.chartConfig = mergeWith(merged, action.payload.config.chart, (objValue, srcValue) => {
                        if (isArray(objValue)) {
                            return objValue.concat(srcValue);
                        }
                    });
                } else {
                    element.chartConfig = {
                        ...defaultConfig,
                        ...action.payload.config.chart
                    };
                }
                element.config = action.payload.config;
                element.fetchStatus = FetchStatus.Success;
                element.n = action.payload.config.n;
                element.tooFewRespondents = action.payload.config.tooFewRespondents;
                element.minimumNumberOfRespondents = action.payload.config.minimumNumberOfRespondents;
                element.additionalExplanations = action.payload.config.additionalExplanations;
            }
        },
        updateElementDataError: (state, action: PayloadAction<UpdateElementFetchStatusActionPayload>) => {
            const element = state.dashboards[action.payload.dashboard]?.elements[action.payload.id];
            if (element) {
                element.fetchStatus = FetchStatus.Error;
                element.chartConfig = {} as DashboardChartConfiguration;
                element.n = -1;
            }
        },
        updateElementSubChartType: (state, action: PayloadAction<UpdateElementSubChartTypeActionPayload>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                element.config.subChartType = action.payload.type;
            }
        },
        updateGlobalFilter: (state, action: PayloadAction<UpdateGlobalFilterActionPayload>) => {
            const dashboard = state.dashboards[state.selectedDashboard]
            if (dashboard) {
                if (action.payload.options.length) {
                    dashboard.selectedFilter[action.payload.name] = action.payload.options;
                } else {
                    delete dashboard.selectedFilter[action.payload.name];
                }
            }
        },
        resetGlobalFilter: state => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                dashboard.selectedFilter = {};
                // Check if dashboard config has defined pre-selected filter which have to be restored and always set
                const preselected = denormalize(dashboard.availableFilter).filter(it => Object.keys(it.selected || {}).length);
                const restrictions: Record<string, ElementRestriction> = {};
                const elementScopeRestrictions: Record<string, ElementRestriction | undefined> = {};
                dashboard.selectedRestrictions = {};

                // Create a restriction object which will be passed into each dashboard element as default restriction
                preselected.forEach(it => {
                    const restriction = {
                        selected: it.selected,
                        name: it.name,
                        filterType: it.filterType,
                        mode: it.mode,
                        availableFilter: denormalize(it.availableFilter || {})
                    };
                    if (it.scope === DashboardFilterScope.Dashboard) {
                        dashboard.selectedRestrictions[it.name] = denormalize(it.availableFilter || {}).filter(filter => it.selected?.includes(filter.id));
                        restrictions[it.name] = restriction;
                    } else if (it.scope === DashboardFilterScope.Element) {
                        elementScopeRestrictions[it.name] = restriction;
                    }
                });
                denormalize(dashboard.elements).filter(it => !it.config.frozen).forEach(dashboardElement => {
                    // Map the element restriction filter into the restrictions;
                    const elementRestrictions = {...restrictions};
                    dashboardElement.config.availableElementFilter?.forEach(it => {
                        if (elementScopeRestrictions[it]) {
                            elementRestrictions[it] = elementScopeRestrictions[it]!;
                        }
                    })
                    // Only reset filter if element is not frozen
                    dashboardElement.config = {
                        ...dashboardElement.config,
                        filter: {},
                        restrictions: elementRestrictions
                    };
                });
            }
        },
        resetElementFilter: (state, action: PayloadAction<DashboardElementPayload>) => {
            const dashboard = state.dashboards[action.payload.dashboard ?? state.selectedDashboard];
            if (dashboard) {
                const element = dashboard.elements[action.payload.id];

                if (element) {
                    const preselected = denormalize(dashboard.availableFilter).filter(it => Object.keys(it.selected || {}).length);
                    element.config.restrictions = {};
                    element.config.filter = {};

                    // Create a restriction object which will be passed into each dashboard element as default restriction
                    preselected.forEach(it => {
                        if (it.scope === DashboardFilterScope.Dashboard || (it.scope === DashboardFilterScope.Element && element.config.availableElementFilter.includes(it.name))) {
                            element.config.restrictions[it.name] = {
                                selected: it.selected,
                                name: it.name,
                                filterType: it.filterType,
                                mode: it.mode,
                                availableFilter: denormalize(it.availableFilter || {})
                            };
                        }
                    });
                }
            }
        },
        applyGlobalFilter: state => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                const restrictions = Object.keys(dashboard.selectedRestrictions || {}).map(id => dashboard.availableFilter[id]).filter(notUndefined);
                const usedRestrictions: Record<string, ElementRestriction> = {};
                const usedElementRestrictions: Record<string, ElementRestriction | undefined> = {};
                restrictions.forEach(restriction => {
                    usedRestrictions[restriction.name] = {
                        ...restriction,
                        availableFilter: undefined,
                        selected: dashboard.selectedRestrictions[restriction.name]?.map(it => it.id.toString(10)) || []
                    };
                });

                // Get all preselected filter
                const preselected = denormalize(dashboard.availableFilter).filter(it => Object.keys(it.selected || {}).length);

                // Use all preselected filter which are not already part of the used restrictions and add it to the used restrictions
                preselected.filter(it => !usedRestrictions[it.name]).forEach(it => {
                    const restriction = {
                        selected: it.selected,
                        name: it.name,
                        filterType: it.filterType,
                        mode: it.mode,
                        availableFilter: denormalize(it.availableFilter || {})
                    };
                    if (it.scope === DashboardFilterScope.Dashboard) {
                        usedRestrictions[it.name] = restriction;
                    } else if (it.scope === DashboardFilterScope.Element) {
                        usedElementRestrictions[it.name] = restriction;
                    }
                });
                denormalize(dashboard.elements).forEach((element) => {
                    // Only apply filter if element is not frozen
                    if (!element.config.frozen) {
                        const elementRestrictions = {...usedRestrictions};
                        element.config.availableElementFilter.forEach(it => {
                            if (usedElementRestrictions[it]) {
                                elementRestrictions[it] = usedElementRestrictions[it]!;
                            }
                        })
                        element.config.filter = {...dashboard.selectedFilter};
                        element.config.restrictions = elementRestrictions;
                    }
                });
            }
        },
        updateGlobalRestriction: (state: DashboardState, action: PayloadAction<UpdateFilterActionPayload>) => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                if (action.payload.options?.length) {
                    dashboard.selectedRestrictions[action.payload.name] = action.payload.options;
                } else if (dashboard.availableFilter[action.payload.name]?.selected?.length) {
                    dashboard.selectedRestrictions[action.payload.name] = dashboard.availableFilter[action.payload.name]?.selected!.map(it => {
                        return dashboard.availableFilter[action.payload.name]!.availableFilter?.[it];
                    }).filter(notUndefined);
                } else {
                    // Check if restriction has default values selected
                    delete dashboard.selectedRestrictions[action.payload.name];
                }
            }
        },
        updateElementRestrictions: (state: DashboardState, action: PayloadAction<UpdateElementRestrictionsActionPayload>) => {
            // Only apply restriction if element is not frozen
            const dashboard = state.dashboards[action.payload.dashboardId || state.selectedDashboard];
            const element = dashboard?.elements[action.payload.id];
            if (dashboard && element && !element.config.frozen) {
                const restriction = dashboard.availableFilter[action.payload.name];
                if (restriction && action.payload.options?.length) {
                    element.config.restrictions[action.payload.name] = {
                        ...restriction,
                        availableFilter: undefined,
                        selected: action.payload.options.map(option => option.id.toString(10))
                    };
                } else if (dashboard.availableFilter[action.payload.name]?.selected?.length) {
                    // Check if restriction has default values selected
                    element.config.restrictions[action.payload.name] = {
                        name: dashboard.availableFilter[action.payload.name]!.name,
                        filterType: dashboard.availableFilter[action.payload.name]!.filterType,
                        mode: dashboard.availableFilter[action.payload.name]!.mode,
                        selected: dashboard.availableFilter[action.payload.name]?.selected!,
                        availableFilter: undefined
                    };
                } else {
                    delete element.config.restrictions[action.payload.name];
                }
            }
        },
        enableLiveMode: (state, action: PayloadAction<boolean>) => {
            state.live.enabled = action.payload;
        },
        enableShowThreshold: (state, action: PayloadAction<EnableShowThresholdActionPayload>) => {
            const dashboard = state.dashboards[state.selectedDashboard];
            const element = dashboard?.elements[action.payload.id];
            if (dashboard && element) {
                (element.config as TableElementConfiguration).showThresholds = action.payload.showThresholds;
            }
        },
        enableShowIcons: (state, action: PayloadAction<EnableShowIconsActionPayload>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                (element.config as TableElementConfiguration).showIcons = action.payload.showIcons;
            }
        },
        setGlobalRefreshTimestamp: (state, action: PayloadAction<SetGlobalRefreshTimestampActionPayload>) => {
            const dashboard = state.dashboards[action.payload.id];
            if (dashboard) {
                dashboard.refreshTimestamp = action.payload.timestamp;
            }
        },
        setSelectedDashboard: (state, action: PayloadAction<number>) => {
            state.selectedDashboard = action.payload;
        },
        setEnabledInline360Images: (state, action: PayloadAction<boolean>) => {
            state.inline360Enabled = action.payload;
        },
        enableOnlyIncludeFinishedFeedback: (state, action: PayloadAction<EnableOnlyIncludeFinishedFeedbackActionPayload>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                (element.config as TableElementConfiguration).includeOnlyFinishedFeedback = action.payload.onlyIncludeFinishedFeedback;
            }
        },
        setTableSearch: (state, action: PayloadAction<SearchTableActionPayload>) => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                // Initialize search if it is still undefined
                dashboard.search = dashboard.search || {};
                dashboard.search[action.payload.id] = {id: action.payload.id, search: action.payload.text};
            }
        },
        setSelectedComparisonPeriod: (state, action: PayloadAction<SetSelectedComparisonPeriodPayload>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                element.config.comparison = element.config.comparison || {};
                element.config.comparison.period = action.payload.period;
                // Reset any comparison periods in the config, will be overwritten in the saga
                element.config.comparisonPeriods = [];
            }
        },
        setCustomComparisonPeriod: (state, action: PayloadAction<SetCustomComparisonPeriodPayload>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload.id];
            if (element?.config) {
                element.config.comparison = element.config.comparison || {};
                element.config.comparison.period = action.payload.type;
                element.config.comparisonPeriods = [action.payload.period];
            }
        },
        resetSelectedComparisonPeriod: (state, action: PayloadAction<number>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload];
            if (element?.config) {
                element.config.comparison = element.config.comparison || {};
                element.config.comparison.period = undefined;
                delete element.config.comparisonPeriods;
            }
        },
        setAllElementComparisonPeriod: (state, action: PayloadAction<ComparisonPeriod>) => {
            denormalize(state.dashboards[state.selectedDashboard]?.elements || {}).forEach(element => {
                if (element.config) {
                    if (element.config.comparisonPeriodHandling !== ComparisonPeriodHandling.IgnoreComparisonPeriod) {
                        element.config.comparison = element.config.comparison || {};
                        element.config.comparison.period = action.payload;
                        // Reset any comparison periods in the config, will be overwritten in the saga
                        element.config.comparisonPeriods = [];
                    } else {
                        delete element.config.comparison;
                        delete element.config.comparisonPeriods;
                    }
                }
            });
        },
        setAllElementCustomComparisonPeriod: (state, action: PayloadAction<SetAllElementsCustomComparisonPeriodPayload>) => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                denormalize(dashboard.elements).forEach(element => {
                    if (element.config) {
                        if (element.config.comparisonPeriodHandling !== ComparisonPeriodHandling.IgnoreComparisonPeriod) {
                            element.config.comparison = element.config.comparison || {};
                            element.config.comparison.period = action.payload.type;
                            element.config.comparisonPeriods = [action.payload.period];
                        } else {
                            delete element.config.comparison;
                            delete element.config.comparisonPeriods;
                        }
                    }
                });
            }
        },
        resetAllElementsSelectedComparisonPeriod: state => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                denormalize(dashboard.elements).forEach(element => {
                    if (element.config) {
                        if (element.config.comparisonPeriodHandling !== ComparisonPeriodHandling.IgnoreComparisonPeriod) {
                            element.config.comparison = element.config.comparison || {};
                            element.config.comparison.period = undefined;
                            delete element.config.comparisonPeriods;
                        } else {
                            delete element.config.comparison;
                            delete element.config.comparisonPeriods;
                        }
                    }
                });
            }
        },
        removeAllElementsSelectedComparisonPeriod: state => {
            const dashboard = state.dashboards[state.selectedDashboard];
            if (dashboard) {
                denormalize(dashboard.elements).forEach(element => {
                    // Check if config enforces setting of comparison period
                    if ((element.config as DataSourceComparisonElementConfiguration).comparisonPeriodHandling === ComparisonPeriodHandling.RequireComparisonPeriod) {
                        element.config.comparison = {};
                    } else {
                        delete element.config.comparison;
                    }
                    delete element.config.comparisonPeriods;
                });
            }
        },
        removeSelectedComparisonPeriod: (state, action: PayloadAction<number>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload];
            // Only delete if element exists and has a defined comparison
            if (element?.config.comparison) {
                if ((element.config as DataSourceComparisonElementConfiguration).comparisonPeriodHandling === ComparisonPeriodHandling.RequireComparisonPeriod) {
                    element.config.comparison = {};
                } else {
                    delete element.config.comparison;
                }
                delete element.config.comparisonPeriods;
            }
        },
        updateElementPage: (state, action: PayloadAction<UpdateElementPagePayload>) => {
            const element = state.dashboards[state.selectedDashboard]?.elements[action.payload.id];
            if (element) {
                element.page = action.payload.page;
            }
        },
        setDashboardElementQuestion: (state, action: PayloadAction<SetDashboardElementQuestionPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element) {
                element.config.historicQuestionGroupIds = [action.payload.questionId];
            }
        },
        setDashboardElementDisplayType: (state, action: PayloadAction<SetDashboardFileElementDisplayTypePayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element && element.config.type === ChartConfigType.Image) {
                (element.config as FileDashboardElementConfiguration).displayType = action.payload.displayType;
            }
        },
        setDashboardElementRankingType: (state, action: PayloadAction<SetDashboardElementRankingTypePayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element) {
                (element.config as AggregatingFeedbackElementConfiguration).selectedRankingType = action.payload.rankingType;
            }
        },
        setDashboardElementRankingOrder: (state, action: PayloadAction<SetDashboardElementRankingOrderPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element) {
                (element.config as DataSourceComparisonElementConfiguration).rankingOrder = action.payload.rankingOrder;
            }
        },
        setDashboardElementGrouping: (state, action: PayloadAction<SetDashboardElementGroupingPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element) {
                (element.config as DataSourceComparisonElementConfiguration).selectedGrouping = action.payload.grouping;
            }
        },
        setDashboardElementRatingFilter: (state, action: PayloadAction<SetDashboardElementRatingFilterPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element?.config.type === ChartConfigType.Text) {
                (element.config as TextElementConfiguration).ratingFilter = action.payload.ratingFilter;
            }
        },
        setDashboardElementPeriodGroupingFilter: (state, action: PayloadAction<SetDashboardElementPeriodGroupingFilterPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element?.config.type === ChartConfigType.LineChart) {
                (element.config as LineChartElementConfiguration).selectedPeriodGrouping = action.payload.periodGrouping;
            }
        },
        setDashboardElementCategoryFilter: (state, action: PayloadAction<SetDashboardElementCategoryFilterPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element?.config.type === ChartConfigType.Text) {
                (element.config as TextElementConfiguration).categoriesFilter = action.payload.categories;
            }
        },
        setDashboardElementSearchFilter: (state, action: PayloadAction<SetDashboardElementSearchFilterPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element?.config.type === ChartConfigType.Text) {
                // Check if element is already in array, then remove it and append it at the end
                (element.config as TextElementConfiguration).textSearchHistory = ((element.config as TextElementConfiguration).textSearchHistory || []).filter(it => !isEqual(it, action.payload.search));
                (element.config as TextElementConfiguration).textSearchHistory!.push(action.payload.search);
                // Check if too many elements are stored in the history, then remove the first one
                if ((element.config as TextElementConfiguration).textSearchHistory!.length > MaximumNumberOfTextSearchHistoryEntries) {
                    // Delete the first element in the search
                    (element.config as TextElementConfiguration).textSearchHistory!.shift();
                }
            }
        },
        removedDashboardElementSearchFilter: (state, action: PayloadAction<SetDashboardElementSearchFilterPayload>) => {
            const element = state.dashboards[action.payload.dashboardId]?.elements[action.payload.elementId];
            if (element?.config.type === ChartConfigType.Text) {
                // Check if element is already in array, then remove it and append it at the end
                (element.config as TextElementConfiguration).textSearchHistory = ((element.config as TextElementConfiguration).textSearchHistory || []).filter(it => !isEqual(it, action.payload.search));
            }
        },
        setDashboardElementExportSuccess: setElementExportFetchStatus(FetchStatus.Success)
    },
    extraReducers: builder => builder.addCase(loadDashboardsActionAndSaga.startAction, state => {
        state.fetchStatus = FetchStatus.Active;
    }).addCase(loadDashboardsActionAndSaga.successAction, (state, action) => {
        state.fetchStatus = FetchStatus.Success;
        if (Array.isArray(action.payload.elements)) {
            const dashboards: Record<string, Dashboard> = {};
            const responseDashboards: ResponseDashboard[] = action.payload.elements.flatMap(it => {
                if (it.type === DashboardResponseType.Group) {
                    return (it as DashboardGroupResponse).group.dashboards;
                } else {
                    return [(it as SingleDashboardResponse).dashboard];
                }
            });

            responseDashboards.forEach(dashboard => {
                dashboards[dashboard.id] = mapDashboard(dashboard);
            });
            state.dashboards = {...state.dashboards, ...dashboards};
            state.groups = {
                ...state.groups,
                ...normalize(action.payload.elements.filter(it => it.type === DashboardResponseType.Group).map((it) => {
                    const element = it as DashboardGroupResponse;
                    return {
                        ...element.group,
                        dashboards: element.group.dashboards.map(it => it.id)
                    }
                }))
            };
            if ((state.selectedDashboard === -1 || !state.dashboards[state.selectedDashboard]) && responseDashboards.length > 0) {
                state.selectedDashboard = responseDashboards[0].id;
            }
        }
    }).addCase(loadDashboardsActionAndSaga.errorAction, state => {
        state.fetchStatus = FetchStatus.Error;
    }).addCase(loadDashboardActionAndSaga.startAction, (state, action) => {
        state.dashboardFetchStatus[action.payload.id] = FetchStatus.Active;
    }).addCase(loadDashboardActionAndSaga.successAction, (state, action) => {
        state.dashboardFetchStatus[action.payload.id] = FetchStatus.Success;
        state.dashboards[action.payload.id] = mapDashboard({...action.payload});
    }).addCase(loadDashboardActionAndSaga.errorAction, (state, action) => {
        state.dashboardFetchStatus[action.payload.id] = FetchStatus.Error;
    }).addCase(loadDashboardActionAndSaga.resetAction, (state, action) => {
        state.dashboardFetchStatus[action.payload.id] = FetchStatus.Default;
    }).addCase(downloadChartAction.startAction, setElementDownloadFetchStatus(FetchStatus.Active))
        .addCase(downloadChartAction.successAction, setElementDownloadFetchStatus(FetchStatus.Success))
        .addCase(downloadChartAction.errorAction, setElementDownloadFetchStatus(FetchStatus.Error))
        .addCase(downloadElementAction.startAction, setElementDownloadFetchStatus(FetchStatus.Active))
        .addCase(downloadElementAction.successAction, setElementDownloadFetchStatus(FetchStatus.Success))
        .addCase(downloadElementAction.errorAction, setElementDownloadFetchStatus(FetchStatus.Error))
        .addCase(exportElementActionAndSaga.startAction, setElementExportFetchStatus(FetchStatus.Active))
        .addCase(exportElementActionAndSaga.resetAction, setElementExportFetchStatus(FetchStatus.Default))
        .addCase(exportElementActionAndSaga.errorAction, setElementExportFetchStatus(FetchStatus.Error))
        .addCase(setSelectedCustomerIdAction.action, state => {
            return {...initialState};
        }).addCase(fetchAuthenticateSuccess, () => {
            return {...initialState};
        })
});

export const {
    setCustomComparisonPeriod,
    removeSelectedComparisonPeriod,
    resetSelectedComparisonPeriod,
    resetAllElementsSelectedComparisonPeriod,
    removeAllElementsSelectedComparisonPeriod,
    setAllElementCustomComparisonPeriod,
    setAllElementComparisonPeriod,
    setSelectedDashboard,
    setSelectedComparisonPeriod,
    updateElementCustomPeriod,
    updateElementDataActive,
    updateElementPage,
    updateElementPeriod,
    updateElementRestrictions,
    updateElementSubChartType,
    updateAllElementsPeriod,
    setDashboardElementQuestion,
    setDashboardElementVisible,
    updateElementFilter,
    updateAllElementsCustomPeriod,
    updateElementDataSuccess,
    updateElementDataError,
    updateGlobalRestriction,
    setGlobalRefreshTimestamp,
    updateGlobalFilter,
    applyGlobalFilter,
    resetGlobalFilter,
    setEnabledInline360Images,
    setTableSearch,
    toggleElementConfig,
    fetchLoadDashboardFilterSuccess,
    enableShowIcons,
    enableShowThreshold,
    enableOnlyIncludeFinishedFeedback,
    enableLiveMode,
    setDashboardElementDisplayType,
    setDashboardElementExportSuccess,
    setDashboardElementRankingType,
    setDashboardElementRankingOrder,
    setDashboardElementGrouping,
    resetElementFilter,
    setDashboardElementRatingFilter,
    setDashboardElementCategoryFilter,
    setDashboardElementSearchFilter,
    removedDashboardElementSearchFilter,
    setDashboardElementPeriodGroupingFilter
} = dashboard.actions;

export default dashboard.reducer;