import React, {lazy} from 'react';
import AppSetup from '../AppSetup';
import {Helmet} from 'react-helmet';
import BarlowThinTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.ttf';
import BarlowThinWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.woff2';
import BarlowThinWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.woff';
import BarlowThinEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100.eot';
import BarlowThinItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.ttf';
import BarlowThinItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.woff2';
import BarlowThinItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.woff';
import BarlowThinItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-100italic.eot';
import BarlowExtraLightTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.ttf';
import BarlowExtraLightWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.woff2';
import BarlowExtraLightWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.woff';
import BarlowExtraLightEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200.eot';
import BarlowExtraLightItalicTTG from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.ttf';
import BarlowExtraLightItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.woff2';
import BarlowExtraLightItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.woff';
import BarlowExtraLightItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-200italic.eot';
import BarlowLightTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.ttf';
import BarlowLightWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.woff2';
import BarlowLightWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.woff';
import BarlowLightEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300.eot';
import BarlowLightItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.ttf';
import BarlowLightItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.woff2';
import BarlowLightItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.woff';
import BarlowLightItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-300italic.eot';
import BarlowTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.ttf';
import BarlowWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.woff2';
import BarlowWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.woff';
import BarlowEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-regular.eot';
import BarlowItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.ttf';
import BarlowItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.woff2';
import BarlowItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.woff';
import BarlowItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-italic.eot';
import BarlowMediumTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.ttf';
import BarlowMediumWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.woff2';
import BarlowMediumWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.woff';
import BarlowMediumEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500.eot';
import BarlowMediumItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.ttf';
import BarlowMediumItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.woff2';
import BarlowMediumItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.woff';
import BarlowMediumItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-500italic.eot';
import BarlowSemiBoldTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.ttf';
import BarlowSemiBoldWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.woff2';
import BarlowSemiBoldWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.woff';
import BarlowSemiBoldEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600.eot';
import BarlowSemiBoldItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.ttf';
import BarlowSemiBoldItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.woff2';
import BarlowSemiBoldItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.woff';
import BarlowSemiBoldItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-600italic.eot';
import BarlowBoldTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.ttf';
import BarlowBoldWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.woff2';
import BarlowBoldWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.woff';
import BarlowBoldEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700.eot';
import BarlowBoldItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.ttf';
import BarlowBoldItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.woff2';
import BarlowBoldItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.woff';
import BarlowBoldItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-700italic.eot';
import BarlowExtraBoldTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.ttf';
import BarlowExtraBoldWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.woff2';
import BarlowExtraBoldWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.woff';
import BarlowExtraBoldEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800.eot';
import BarlowExtraBoldItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.ttf';
import BarlowExtraBoldItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.woff2';
import BarlowExtraBoldItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.woff';
import BarlowExtraBoldItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-800italic.eot';
import BarlowBlackTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.ttf';
import BarlowBlackWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.woff2';
import BarlowBlackWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.woff';
import BarlowBlackEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900.eot';
import BarlowBlackItalicTTF from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.ttf';
import BarlowBlackItalicWoff2 from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.woff2';
import BarlowBlackItalicWoff from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.woff';
import BarlowBlackItalicEOT from '../../fonts/Barlow/barlow-v11-latin-ext_latin-900italic.eot';
import BarlowCondensedThinTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.ttf';
import BarlowCondensedThinWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.woff2';
import BarlowCondensedThinWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.woff';
import BarlowCondensedThinEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100.eot';
import BarlowCondensedThinItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.ttf';
import BarlowCondensedThinItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.woff2';
import BarlowCondensedThinItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.woff';
import BarlowCondensedThinItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-100italic.eot';
import BarlowCondensedExtraLightTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.ttf';
import BarlowCondensedExtraLightWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.woff2';
import BarlowCondensedExtraLightWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.woff';
import BarlowCondensedExtraLightEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200.eot';
import BarlowCondensedExtraLightItalicTTG
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.ttf';
import BarlowCondensedExtraLightItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.woff2';
import BarlowCondensedExtraLightItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.woff';
import BarlowCondensedExtraLightItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-200italic.eot';
import BarlowCondensedLightTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.ttf';
import BarlowCondensedLightWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.woff2';
import BarlowCondensedLightWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.woff';
import BarlowCondensedLightEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300.eot';
import BarlowCondensedLightItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.ttf';
import BarlowCondensedLightItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.woff2';
import BarlowCondensedLightItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.woff';
import BarlowCondensedLightItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-300italic.eot';
import BarlowCondensedTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.ttf';
import BarlowCondensedWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.woff2';
import BarlowCondensedWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.woff';
import BarlowCondensedEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-regular.eot';
import BarlowCondensedItalicTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.ttf';
import BarlowCondensedItalicWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.woff2';
import BarlowCondensedItalicWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.woff';
import BarlowCondensedItalicEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-italic.eot';
import BarlowCondensedMediumTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.ttf';
import BarlowCondensedMediumWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.woff2';
import BarlowCondensedMediumWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.woff';
import BarlowCondensedMediumEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500.eot';
import BarlowCondensedMediumItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.ttf';
import BarlowCondensedMediumItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.woff2';
import BarlowCondensedMediumItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.woff';
import BarlowCondensedMediumItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-500italic.eot';
import BarlowCondensedSemiBoldTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.ttf';
import BarlowCondensedSemiBoldWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.woff2';
import BarlowCondensedSemiBoldWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.woff';
import BarlowCondensedSemiBoldEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600.eot';
import BarlowCondensedSemiBoldItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.ttf';
import BarlowCondensedSemiBoldItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.woff2';
import BarlowCondensedSemiBoldItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.woff';
import BarlowCondensedSemiBoldItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-600italic.eot';
import BarlowCondensedBoldTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.ttf';
import BarlowCondensedBoldWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.woff2';
import BarlowCondensedBoldWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.woff';
import BarlowCondensedBoldEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700.eot';
import BarlowCondensedBoldItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.ttf';
import BarlowCondensedBoldItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.woff2';
import BarlowCondensedBoldItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.woff';
import BarlowCondensedBoldItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-700italic.eot';
import BarlowCondensedExtraBoldTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.ttf';
import BarlowCondensedExtraBoldWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.woff2';
import BarlowCondensedExtraBoldWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.woff';
import BarlowCondensedExtraBoldEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800.eot';
import BarlowCondensedExtraBoldItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.ttf';
import BarlowCondensedExtraBoldItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.woff2';
import BarlowCondensedExtraBoldItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.woff';
import BarlowCondensedExtraBoldItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-800italic.eot';
import BarlowCondensedBlackTTF from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.ttf';
import BarlowCondensedBlackWoff2 from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.woff2';
import BarlowCondensedBlackWoff from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.woff';
import BarlowCondensedBlackEOT from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900.eot';
import BarlowCondensedBlackItalicTTF
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.ttf';
import BarlowCondensedBlackItalicWoff2
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.woff2';
import BarlowCondensedBlackItalicWoff
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.woff';
import BarlowCondensedBlackItalicEOT
    from '../../fonts/BarlowCondensed/barlow-condensed-v11-latin-ext_latin-900italic.eot';
import MyWritingTTF from '../../fonts/MyWriting/Mywriting.ttf';
import MyWritingWoff from '../../fonts/MyWriting/Mywriting.woff';
import MyWritingWoff2 from '../../fonts/MyWriting/Mywriting.woff2';
import {Skeleton} from '@software/reactcommons';
import {Messages, messages} from '../../localization/localization';
import Routes from '../../constants/routes';
import {SecuredComponent} from '@software/reactcommons-security';
import {Role} from '../../redux/reducer/user/types';
import QualitizeDnDProvider from './dnd/QualitizeDnDProvider';
import theme from '../theme/Theme';
import UserRedirection from '../sites/UserRedirection';
import {useSelector} from 'react-redux';
import {AppState} from '../../types/Types';
import {CheckOnlineStateRoutes} from '../../api/Api';
import OnlineStateNotification from '../network/OnlineStateNotification';

const Login = lazy(() => import('../sites/authentication/Login'));
const ReportDownload = lazy(() => import ('../sites/feedback/reports/download/ReportDownload'));
const Videos = lazy(() => import('../sites/video/Videos'));
const Operations = lazy(() => import('../sites/operations/Operations'));
const Feedback = lazy(() => import('../sites/feedback/Feedback'));
const Settings = lazy(() => import('../sites/administration/settings/Settings'));
const Administration = lazy(() => import('../sites/administration/Administration'));
const LandingPages = lazy(() => import('../sites/landingPages/LandingPages'));
const DownloadDashboardElementExportSite = lazy(() => import('../sites/feedback/dashboard/DownloadDashboardElementExportSite'));

const routesToComponents = {
    [Routes.Login.Base('/*')]: {
        component: (<Login/>)
    },
    [Routes.FeedbackBase(Routes.Feedback.Reports.Base(Routes.Feedback.Reports.Download(':id')))]: {
        component: (<ReportDownload/>)
    },
    [Routes.Download.Base(Routes.Download.DashboardElementExport(':opaqueId'))]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[]} key={'route-download-dashboard-element-export'}>
            <DownloadDashboardElementExportSite/>
        </SecuredComponent>)
    },
    [Routes.FeedbackBase('/*')]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[]} key={'route-feedback'}>
            <Feedback/>
        </SecuredComponent>)
    },
    [Routes.OperationsBase('/*')]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[Role.Operations]}
                                      key={'route-operations'}>
            <Operations/>
        </SecuredComponent>)
    },
    [Routes.AdministrationBase('/*')]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[]} key={'route-settings'}>
            <Administration/>
        </SecuredComponent>)
    },
    [Routes.Videos.Base('/*')]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[Role.VideoUser]} key={'route-videos'}>
            <Videos/>
        </SecuredComponent>)
    },
    [Routes.LandingPageBase('/*')]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[Role.LandingPageRead]} key={'route-landing-pages'}>
            <LandingPages/>
        </SecuredComponent>)
    },
    [Routes.Settings.Base('/*')]: {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[]} key={'route-settings'}>
            <Settings/>
        </SecuredComponent>)
    },
    '/*': {
        component: (<SecuredComponent loginRoute={Routes.Login.Base('')}
                                      requiredRoles={[]} key={'route-user-redirection'}>
            <UserRedirection/>
        </SecuredComponent>)
    }
}

const Structure: React.FC = () => {

    const lang = useSelector((state: AppState) => state.application.language);

    return (
        <Skeleton<Messages> messages={messages} theme={theme} routesToComponent={routesToComponents}>
            <QualitizeDnDProvider>
                <Helmet htmlAttributes={{lang}}>
                    <link rel={'font'} href={BarlowThinTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinItalicTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinItalicWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinItalicWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowThinItalicEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightItalicTTG} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightItalicWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightItalicWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraLightItalicEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightItalicTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightItalicWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightItalicWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowLightItalicEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowItalicTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowItalicEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowMediumItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowSemiBoldItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldWoff2} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBoldItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowExtraBoldItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackTTF} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackWoff} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackEOT} crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowBlackItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedThinItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightItalicTTG}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraLightItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedLightItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedMediumItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedSemiBoldItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBoldItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedExtraBoldItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackItalicTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackItalicWoff2}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackItalicWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={BarlowCondensedBlackItalicEOT}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={MyWritingTTF}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={MyWritingWoff}
                          crossOrigin={'anonymous'}/>
                    <link rel={'font'} href={MyWritingWoff2}
                          crossOrigin={'anonymous'}/>
                </Helmet>
                <OnlineStateNotification url={CheckOnlineStateRoutes.QualitizeOperations}/>
                <AppSetup/>
            </QualitizeDnDProvider>
        </Skeleton>
    );
}

export default Structure;